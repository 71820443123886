/**
 * 系统管理-用户管理-重置密码
 * luxinwen
 * 2022-08
 */
<template>
  <div>
    <Drawer class="drawer" title="重置密码" v-model="myValue" width="600" :before-close="beforeClose">
      <Form ref="editForm" :model="formData" :rules="formRule" :label-width="100">
        <FormItem label="用户名称">
          <Input v-model.trim="formData.userName" disabled />
        </FormItem>
        <FormItem label="登录名称">
          <Input v-model.trim="formData.loginName" disabled />
        </FormItem>
        <FormItem label="手机">
          <Input v-model.trim="formData.userMobile" disabled />
        </FormItem>
        <FormItem label="新密码" prop="newPassword">
          <Input v-model="formData.newPassword" maxlength="20" type="password" password></Input>
          <p class="text-tips">*密码由大写字母、小写字母和数字组成，长度为8-20位</p>
        </FormItem>
        <FormItem label="确认密码" prop="confirmPassword">
          <Input v-model="formData.confirmPassword" maxlength="20" type="password" password></Input>
          <p class="text-tips">*密码由大写字母、小写字母和数字组成，长度为8-20位</p>
        </FormItem>
      </Form>
      <div class="drawer-foot">
        <Button type="primary" @click="submit">提交</Button>
      </div>
      <Spin size="large" fix v-if="isLoading"></Spin>
    </Drawer>
  </div>
</template>

<script>
  import { encrypt } from 'rsaEncrypt';
  const formData = {
    userName: '',
    loginName: '',
    userMobile: '',
    newPassword: '',
    confirmPassword: ''
  };

  export default {
    props: {
      value: {
        type: Boolean,
        default: false
      },
      data: {
        type: Object,
        default() {
          return {};
        }
      }
    },
    data() {
      return {
        myValue: this.value,  // 是否显示抽屉
        isInit: false,        // 是否初始化
        isLoading: false,     // 是否加载中
        formData: Object.assign({}, formData),
        formRule: {
          newPassword: [
            {
              required: true,
              validator: (rule, value, callback) => {
                if (value.length === 0) {
                  callback(new Error('请输入新密码'));
                } else if (value.length < 8) {
                  callback(new Error('登录密码不能少于8位'));
                } else if (!/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d~!@#$\-_.]*$/.test(value)) {
                  callback(new Error('密码须包含大小写字母及数字'));
                } else {
                  callback();
                }
              },
              trigger: 'blur'
            }
          ],
          confirmPassword: [
            {
              required: true,
              validator: (rule, value, callback) => {
                if (value.length === 0) {
                  callback(new Error('请输入确认密码'));
                } else if (value.length < 8) {
                  callback(new Error('登录密码不能少于8位'));
                } else if (!/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d~!@#$\-_.]*$/.test(value)) {
                  callback(new Error('密码须包含大小写字母及数字'));
                } else if (value !== this.formData.newPassword) {
                  callback(new Error('确认密码与新密码不一样'));
                } else {
                  callback();
                }
              },
              trigger: 'blur'
            }
          ]
        },
        backupData: {}
      };
    },
    computed: {
      isEqual() {
        return this.deepEqual(this.formData, this.backupData);
      }
    },
    methods: {
      /**
       * 初始化数据
       */
      initData(data = {}, isInit = false) {
        this.formData = this.deepCopy(formData);
        Object.keys(this.formData).forEach(item => {
          if (data.hasOwnProperty(item)) {
            this.formData[item] = data[item];
          }
        });
        if (isInit) {
          this.$nextTick(() => {
            this.backupData = this.deepCopy(this.formData);
            this.isInit = true;
          });
        }
      },
      /**
       * 获取数据
       */
      getData() {
        this.initData(this.data, true);
      },
      /**
       * 关闭前的回调
       */
      beforeClose() {
        return new Promise((resolve, reject) => {
          if (!this.isInit) {
            // 如果未初始化时快速点击关闭，则不做处理
          } else if (this.isEqual) {
            this.myValue = false;
          } else {
            this.$confirm('数据变更后未保存，是否直接关闭？').then(() => {
              this.myValue = false;
            });
          }
        });
      },
      /**
       * 提交
       */
      submit() {
        this.$refs.editForm.validate(valid => {
          if (valid) {
            this.$confirm('确定要提交吗？').then(() => {
              let url = this.$api.system.resetPasscode;
              let data = {
                loginName: this.formData.loginName,
                newPassword: encrypt(this.formData.newPassword)
              };
              this.save(url, data);
            });
          }
        });
      },
      /**
       * 保存
       */
      save(url, data) {
        this.$axios({
          url,
          data,
          beforeSend: () => {
            this.isLoading = true;
          },
          complete: () => {
            this.isLoading = false;
          }
        }).then(data => {
          this.notice({
            type: 'success',
            desc: '重置密码成功'
          });
          this.myValue = false;
          this.$emit('success');
        });
      }
    },
    watch: {
      value(val) {
        this.myValue = val;
        if (this.myValue) {
          // 获取数据
          this.getData();
          // 隐藏页面滚动条
          document.querySelector('body').classList.add('hidden');
          // 重置内容域滚动条
          this.$nextTick(() => {
            let $drawer = document.querySelector('.drawer .ivu-drawer-body');
            if ($drawer) $drawer.scrollTo(0, 0);
          });
        } else {
          // 清除表单验证效果
          this.$refs.editForm && this.$refs.editForm.resetFields();
          // 重置初始化状态
          this.isInit = false;
          // 恢复页面滚动条
          document.querySelector('body').classList.remove('hidden');
        }
      },
      myValue(val) {
        this.$emit('input', val);
      }
    }
  };
</script>