/**
 * 系统管理-用户管理-编辑页
 * luxinwen
 * 2022-08
 */
<template>
  <div>
    <Drawer class="drawer" :title="title" v-model="myValue" width="600" :before-close="beforeClose">
      <Form ref="editForm" :model="formData" :rules="formRule" :label-width="100">
        <FormItem label="用户名称" prop="userName">
          <Input v-model.trim="formData.userName" maxlength="30" clearable />
        </FormItem>
        <FormItem label="登录名称" prop="loginName">
          <Input v-model.trim="formData.loginName" maxlength="30" clearable :disabled="isEdit" />
        </FormItem>
        <FormItem label="登录密码" prop="password" v-if="!isEdit">
          <Input v-model.trim="formData.password" maxlength="20" clearable />
          <p class="text-tips">密码由大、小写字母和数字组成，长度为8-20位</p>
        </FormItem>
        <FormItem label="手机" prop="userMobile">
          <Input v-model.trim="formData.userMobile" maxlength="11" clearable />
        </FormItem>
        <FormItem label="邮箱" prop="userEmail">
          <Input v-model.trim="formData.userEmail" maxlength="50" clearable />
        </FormItem>
        <!-- <FormItem label="归属商户" prop="merchantCode">
          <Select v-model="formData.merchantCode" placeholder="请选择归属商户" clearable filterable :disabled="isEdit" @on-change="merchantChange">
            <Option v-for="item in merchantList" :key="'merchant' + item.merchantCode" :value="item.merchantCode">{{ item.merchantNameZh }}</Option>
          </Select>
          <p class="text-tips">商户中心用户必选，不选默认为平台用户</p>
        </FormItem>
        <FormItem label="归属店铺" prop="storeCode">
          <Select v-model="formData.storeCode" placeholder="请选择上级菜单" clearable filterable multiple @on-clear="merchantChange">
            <Option v-for="item in storeList" :key="'store' + item.storeCode" :value="item.storeCode">{{ item.storeName }}</Option>
          </Select>
          <p class="text-tips">商户中心用户必选，不选默认为平台用户</p>
        </FormItem> -->
      </Form>
      <div class="drawer-foot" v-show="!isShow">
        <Button type="primary" @click="submit">提交</Button>
      </div>
      <Spin size="large" fix v-if="isLoading"></Spin>
    </Drawer>
  </div>
</template>

<script>
  import { encrypt } from 'rsaEncrypt';
  const formData = {
    userName: '',   // 用户名称
    loginName: '',  // 登录名称
    password: '',   // 登录密码
    userMobile: '', // 手机
    userEmail: '',  // 邮箱
    merchantCode: '',
    storeCode: []
  };

  export default {
    props: {
      value: {
        type: Boolean,
        default: false
      },
      data: {
        type: Object,
        default() {
          return {};
        }
      },
      flag: {
        type: String
      }
    },
    data() {
      return {
        myValue: this.value,  // 是否显示抽屉
        isInit: false,        // 是否初始化
        isLoading: false,     // 是否加载中
        formData: Object.assign({}, formData),
        formRule: {
          userName: [
            {
              required: true,
              message: '请输入用户名称',
              trigger: 'blur'
            }
          ],
          loginName: [
            {
              required: true,
              message: '请输入登录名称',
              trigger: 'blur'
            },
            {
              pattern: /^[\da-zA-Z_-]*$/,
              message: '只能输入数字、字母、横线和下划线',
              trigger: 'blur'
            }
          ],
          password: [
            {
              required: true,
              message: '请输入登录密码',
              trigger: 'blur'
            },
            {
              min: 8,
              message: '登录密码不能少于8位',
              trigger: 'blur'
            },
            {
              pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d~!@#$\-_.]*$/,
              message: '密码须包含大小写字母及数字',
              trigger: 'blur'
            }
          ],
          userMobile: [
            {
              required: true,
              message: '请输入手机',
              trigger: 'blur'
            },
            {
              pattern: /^[1]\d{10}$/,
              message: '请正确输入手机',
              trigger: 'blur'
            }
          ],
          userEmail: [
            {
              required: true,
              message: '请输入邮箱',
              trigger: 'blur'
            },
            {
              pattern: /^(\w)+(\.\w+)*@(\w)+((\.\w+)+)$/,
              message: '请正确输入邮箱',
              trigger: 'blur'
            }
          ]
        },
        backupData: {},
        // merchantList: [],
        // storeList: []
      };
    },
    computed: {
      title() {
        return {
          'add': '新增',
          'show': '查看',
          'edit': '编辑'
        }[this.flag] + '用户';
      },
      isEqual() {
        return this.deepEqual(this.formData, this.backupData);
      },
      isShow() {
        return this.flag === 'show';
      },
      isEdit() {
        return this.flag === 'edit';
      }
    },
    methods: {
      /**
       * 初始化数据
       */
      initData(data = {}, isInit = false) {
        this.formData = this.deepCopy(formData);
        Object.keys(this.formData).forEach(item => {
          if (data.hasOwnProperty(item)) {
            this.formData[item] = data[item];
          }
        });
        if (isInit) {
          this.$nextTick(() => {
            this.backupData = this.deepCopy(this.formData);
            this.isInit = true;
          });
        }
      },
      /**
       * 获取数据
       */
      getData() {
        this.initData(this.data, true);
        // this.getMerchantList();
        // this.getStoreList();
      },
      /**
       * 关闭前的回调
       */
      beforeClose() {
        return new Promise((resolve, reject) => {
          if (!this.isInit) {
            // 如果未初始化时快速点击关闭，则不做处理
          } else if (this.isEqual) {
            this.myValue = false;
          } else {
            this.$confirm('数据变更后未保存，是否直接关闭？').then(() => {
              this.myValue = false;
            });
          }
        });
      },
      /**
       * 提交
       */
      submit() {
        this.$refs.editForm.validate(valid => {
          if (valid) {
            this.$confirm('确定要提交吗？').then(() => {
              let url = this.$api.system.addUser;
              let data = Object.assign({}, this.formData);
              data.storeCode = data.storeCode.join(',');
              if (this.isEdit) {
                url = this.$api.system.updateUser;
                data.id = this.data.id;
                delete data.password;
              } else {
                data.password = encrypt(data.password);
              }
              this.save(url, data);
            });
          }
        });
      },
      /**
       * 保存
       */
      save(url, data) {
        this.$axios({
          url,
          data,
          beforeSend: () => {
            this.isLoading = true;
          },
          complete: () => {
            this.isLoading = false;
          }
        }).then(data => {
          this.notice({
            type: 'success',
            desc: '提交成功'
          });
          this.myValue = false;
          this.$emit('success');
        });
      },
      /**
       * 获取商户列表
       */
      // getMerchantList() {
      //   this.$axios({
      //     url: this.$api.merchant.queryAll,
      //     data: {}
      //   }).then(data => {
      //     this.merchantList = data || [];
      //   });
      // },
      /**
       * 选择商户后触发 获取店铺
       */
      // merchantChange() {
      //   this.storeList = [];
      //   this.formData.storeCode = [];
      //   if (this.formData.merchantCode) {
      //     this.getStoreList();
      //   }
      // },
      /**
       * 获取门店列表
       */
      // getStoreList(query) {
      //   let data = {
      //     pageNum: 1,
      //     pageSize: 100,
      //     merchantCode: this.formData.merchantCode
      //   };
      //   if (query) {
      //     data.storeName = query;
      //   }
      //   this.$axios({
      //     url: this.$api.merchant.queryStoreList,
      //     data
      //   }).then(data => {
      //     this.storeList = data.list || [];
      //   });
      // }
    },
    watch: {
      value(val) {
        this.myValue = val;
        if (this.myValue) {
          // 获取数据
          this.getData();
          // 隐藏页面滚动条
          document.querySelector('body').classList.add('hidden');
          // 重置内容域滚动条
          this.$nextTick(() => {
            let $drawer = document.querySelector('.drawer .ivu-drawer-body');
            if ($drawer) $drawer.scrollTo(0, 0);
          });
        } else {
          // 清除表单验证效果
          this.$refs.editForm && this.$refs.editForm.resetFields();
          // 重置初始化状态
          this.isInit = false;
          // 恢复页面滚动条
          document.querySelector('body').classList.remove('hidden');
        }
      },
      myValue(val) {
        this.$emit('input', val);
      }
    }
  };
</script>