/**
 * 系统管理-用户管理
 * luxinwen
 * 2022-08
 */
<template>
  <div class="page">
    <div class="page-main">
      <div class="page-main-content">
        <Button type="primary" @click="addData" v-grant="'OSP_SYS_USER_SAVE'">新增用户</Button>
        <div class="right-form" v-grant="'OSP_SYS_USER_QUERY'">
          <Input class="width-m" v-model.trim="formData.userName" suffix="ios-search" placeholder="请输入用户名称/登录名称进行过滤" clearable @on-enter="queryData" />
        </div>
      </div>
      <div class="page-main-content">
        <sp-table :columns="filterColumns" :data="tableData" :loading="tableLoading" :total="pager.total" :page="pager.pageNum" :page-size="pager.pageSize" @change="changePage">
          <template slot-scope="params" slot="status">
            <span :class="{'text-red': params.row.status === '2'}">{{ params.row.status === '1' ? '正常' : '禁用' }}</span>
          </template>
          <template slot-scope="params" slot="operation">
            <Button @click="editData(params.row)" v-grant="'OSP_SYS_USER_UPDATE'">编辑</Button>
            <Dropdown transfer @on-click="moreAction($event, params.row)" v-grant="['OSP_SYS_USER_SETROLE', 'OSP_SYS_USER_RESET', 'OSP_SYS_USER_CHANGE', 'OSP_SYS_USER_DELETE']">
              <Button>更多 <Icon type="ios-arrow-down"></Icon></Button>
              <DropdownMenu slot="list">
                <DropdownItem name="role" v-grant="'OSP_SYS_USER_SETROLE'">设置角色</DropdownItem>
                <DropdownItem name="pwd" v-grant="'OSP_SYS_USER_RESET'">重置密码</DropdownItem>
                <DropdownItem name="disable" :class="{'text-red': params.row.status === '1'}" v-grant="'OSP_SYS_USER_CHANGE'">{{ params.row.status === '1' ? '禁用' : '启用' }}</DropdownItem>
                <DropdownItem name="delete" class="text-red" v-grant="'OSP_SYS_USER_DELETE'">删除</DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </template>
        </sp-table>
      </div>
    </div>

    <edit-drawer v-model="drawerDisplay" :data="drawerData" :flag="drawerFlag" @success="getData"></edit-drawer>
    <set-role v-model="roleDisplay" :data="roleData" @success="getData"></set-role>
    <set-pass v-model="passDisplay" :data="passData" @success="getData"></set-pass>
  </div>
</template>

<script>
  import editDrawer from './edit.vue';
  import setPass from './set-pass.vue';
  import setRole from './set-role.vue';

  export default {
    components: {
      editDrawer,
      setPass,
      setRole
    },
    data() {
      return {
        formData: {
          userName: ''
        },
        tableColumns: [
          {
            title: '序号',
            type: 'index',
            width: 70,
            align: 'center'
          },
          {
            title: '用户名称',
            key: 'userName'
          },
          {
            title: '登录名称',
            key: 'loginName'
          },
          {
            title: '手机号',
            key: 'userMobile'
          },
          {
            title: '归属平台',
            render: (h, params) => {
              return h('div', params.row.merchantCode === 'OSP' ? '运营平台' : '商户中心');
            }
          },
          {
            title: '角色',
            render: (h, params) => {
              let roles = params.row.sysRoles || [];
              let names = roles.map(item => item.roleName);
              return h('div', names.join(','));
            }
          },
          {
            title: '状态',
            slot: 'status'
          },
          {
            title: '操作',
            slot: 'operation',
            align: 'center'
          }
        ],
        tableData: [],
        tableLoading: false, // 表格查询状态
        pager: {
          total: 0,     // 总条数
          pageNum: 1,   // 当前页码
          pageSize: 10  // 每页条数
        },
        drawerDisplay: false, // 是否显示编辑抽屉
        drawerData: {},       // 编辑抽屉数据
        drawerFlag: 'add',    // 编辑抽屉状态
        roleDisplay: false,   // 是否显示角色弹窗
        roleData: {},         // 角色数据
        passDisplay: false,
        passData: {}
      };
    },
    computed: {
      filterColumns() {
        let val = [];
        let grant = [this.$grant.OSP_SYS_USER_UPDATE, this.$grant.OSP_SYS_USER_SETROLE, this.$grant.OSP_SYS_USER_RESET, this.$grant.OSP_SYS_USER_CHANGE, this.$grant.OSP_SYS_USER_DELETE];
        if (!this.checkGrant(grant)) {
          val = this.tableColumns.filter(item => item.slot !== 'operation');
        } else {
          val = this.tableColumns;
        }
        return val;
      }
    },
    created() {
      this.getData();
    },
    methods: {
      /**
       * 获取数据
       */
      getData() {
        let data = Object.assign({}, this.formData, {
          pageNum: this.pager.pageNum,
          pageSize: this.pager.pageSize
        });
        this.$axios({
          url: this.$api.system.queryUsers,
          data,
          beforeSend: () => {
            this.tableLoading = true;
          },
          complete: () => {
            this.tableLoading = false;
          }
        }).then(data => {
          this.tableData = data.list || [];
          this.pager.total = data.total || 0;
        });
      },
      /**
       * 查询数据
       */
      queryData() {
        this.pager.pageNum = 1;
        this.getData();
      },
      /**
       * 新增数据
       */
      addData() {
        this.drawerFlag = 'add';
        this.drawerData = {};
        this.drawerDisplay = true;
      },
      /**
       * 编辑数据
       */
      editData(row) {
        this.$axios({
          url: this.$api.system.queryUserDetail,
          data: {
            id: row.id
          },
          loading: true
        }).then(data => {
          let drawerData = Object.assign({}, data, {
            storeCode: []
          });
          if (data.storeCode) {
            drawerData.storeCode = data.storeCode.split(',');
          }
          this.drawerFlag = 'edit';
          this.drawerData = drawerData;
          this.drawerDisplay = true;
        });
      },
      /**
       * 更多
       */
      moreAction(name, row) {
        switch (name) {
        case 'role':
          this.editRole(row);
          break;
        case 'pwd':
          this.changePwd(row);
          break;
        case 'disable':
          this.disableData(row);
          break;
        case 'delete':
          this.removeData(row);
          break;
        }
      },
      /**
       * 设置角色
       */
      editRole(row) {
        let roleCodes = [];
        if (row.sysRoles && row.sysRoles.length) {
          roleCodes = row.sysRoles.map(item => item.roleId);
        }
        this.roleData = {
          userName: row.userName,
          userId: row.id,
          merchantCode: row.merchantCode,
          roleCodes
        };
        this.roleDisplay = true;
      },
      /**
       * 修改密码
       */
      changePwd(row) {
        this.passData = {
          userName: row.userName,
          loginName: row.loginName,
          userMobile: row.userMobile,
          newPassword: '',
          confirmPassword: ''
        };
        this.passDisplay = true;
      },
      /**
       * 禁用、启用
       */
      disableData(row) {
        let txt = row.status === '1' ? '禁用' : '启用';
        let status = row.status === '1' ? '2' : '1';
        this.$confirm(`确定要将 [${row.userName}] 设置为${txt}吗？`).then(() => {
          this.$axios({
            url: this.$api.system.disableAccount,
            data: {
              id: row.id,
              status
            },
            loading: true
          }).then(data => {
            this.notice({
              type: 'success',
              desc: txt + '成功'
            });
            this.getData();
          });
        });
      },
      /**
       * 删除数据
       */
      removeData(row) {
        this.$confirm(`确定要删除 [${row.userName}] 吗？`).then(() => {
          this.$axios({
            url: this.$api.system.deleteUser,
            data: {
              id: row.id,
              loginName: row.loginName
            },
            loading: true
          }).then(data => {
            this.notice({
              type: 'success',
              desc: '删除成功'
            });
            this.getData();
          });
        });
      },
      /**
       * 切换页码或每条页数
       */
      changePage(pager) {
        this.pager.pageNum = pager.page;
        this.pager.pageSize = pager.pageSize;
        this.getData();
      }
    }
  };
</script>