/**
 * 系统管理-用户管理-设置角色
 * luxinwen
 * 2022-08
 */
<template>
  <div>
    <Drawer class="drawer" title="设置角色" v-model="myValue" width="600" :before-close="beforeClose">
      <Form ref="editForm" :model="formData" :rules="formRule" :label-width="100">
        <FormItem label="用户名称">
          <Input v-model.trim="formData.userName" disabled />
        </FormItem>
        <FormItem label="用户角色" prop="roleCodes">
          <Select v-model="formData.roleCodes" placeholder="请选择用户角色" multiple clearable>
            <Option v-for="item in roleShowList" :key="'role' + item.roleId" :value="item.roleId">{{ item.roleName }}</Option>
          </Select>
        </FormItem>
      </Form>
      <div class="drawer-foot">
        <Button type="primary" @click="submit">提交</Button>
      </div>
      <Spin size="large" fix v-if="isLoading"></Spin>
    </Drawer>
  </div>
</template>

<script>
  const formData = {
    userName: '',
    userId: '',
    merchantCode: '',
    roleCodes: []
  };

  export default {
    props: {
      value: {
        type: Boolean,
        default: false
      },
      data: {
        type: Object,
        default() {
          return {};
        }
      }
    },
    data() {
      return {
        myValue: this.value,  // 是否显示抽屉
        isInit: false,        // 是否初始化
        isLoading: false,     // 是否加载中
        formData: Object.assign({}, formData),
        formRule: {
          roleCodes: [
            {
              type: 'array',
              required: true,
              message: '请选择用户角色',
              trigger: 'change'
            }
          ]
        },
        backupData: {},
        roleList: []
      };
    },
    computed: {
      isEqual() {
        return this.deepEqual(this.formData, this.backupData);
      },
      roleShowList() {
        let val = [];
        if (this.roleList && this.roleList.length > 0) {
          if (this.formData.merchantCode === 'OSP') {
            val = this.roleList.filter(item => item.merchantCode === 'OSP');
          } else {
            val = this.roleList.filter(item => item.merchantCode !== 'OSP');
          }
        }
        return val;
      }
    },
    created() {
      this.getRoleData();
    },
    methods: {
      /**
       * 初始化数据
       */
      initData(data = {}, isInit = false) {
        this.formData = this.deepCopy(formData);
        Object.keys(this.formData).forEach(item => {
          if (data.hasOwnProperty(item)) {
            this.formData[item] = data[item];
          }
        });
        if (isInit) {
          this.$nextTick(() => {
            this.backupData = this.deepCopy(this.formData);
            this.isInit = true;
          });
        }
      },
      /**
       * 获取数据
       */
      getData() {
        this.initData(this.data, true);
      },
      /**
       * 关闭前的回调
       */
      beforeClose() {
        return new Promise((resolve, reject) => {
          if (!this.isInit) {
            // 如果未初始化时快速点击关闭，则不做处理
          } else if (this.isEqual) {
            this.myValue = false;
          } else {
            this.$confirm('数据变更后未保存，是否直接关闭？').then(() => {
              this.myValue = false;
            });
          }
        });
      },
      /**
       * 提交
       */
      submit() {
        this.$refs.editForm.validate(valid => {
          if (valid) {
            this.$confirm('确定要提交吗？').then(() => {
              let url = this.$api.system.bindRole;
              let data = {
                userId: this.formData.userId,
                roleIdList: this.formData.roleCodes
              };
              this.save(url, data);
            });
          }
        });
      },
      /**
       * 保存
       */
      save(url, data) {
        this.$axios({
          url,
          data,
          beforeSend: () => {
            this.isLoading = true;
          },
          complete: () => {
            this.isLoading = false;
          }
        }).then(data => {
          this.notice({
            type: 'success',
            desc: '设置角色成功'
          });
          this.myValue = false;
          this.$emit('success');
        });
      },
      /**
       * 获取角色选项
       */
      getRoleData() {
        this.$axios({
          url: this.$api.system.queryRoles,
          data: {}
        }).then(data => {
          this.roleList = data || [];
        });
      }
    },
    watch: {
      value(val) {
        this.myValue = val;
        if (this.myValue) {
          // 获取数据
          this.getData();
          // 隐藏页面滚动条
          document.querySelector('body').classList.add('hidden');
          // 重置内容域滚动条
          this.$nextTick(() => {
            let $drawer = document.querySelector('.drawer .ivu-drawer-body');
            if ($drawer) $drawer.scrollTo(0, 0);
          });
        } else {
          // 清除表单验证效果
          this.$refs.editForm && this.$refs.editForm.resetFields();
          // 重置初始化状态
          this.isInit = false;
          // 恢复页面滚动条
          document.querySelector('body').classList.remove('hidden');
        }
      },
      myValue(val) {
        this.$emit('input', val);
      }
    }
  };
</script>